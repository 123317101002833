<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">组织机构</div>
      <div class="content">
        <div class="organization">
          <el-tree
            :data="treeList"
            :props="defaultProps"
            accordion
            v-loading="loadingTwo"
            element-loading-text="正在加载数据"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0,.1)"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="tabBox">
          <div class="search">
            <div class="demo-input-suffix">
              <div class="account">
                <span class="title">机构编号：</span>
                <el-input placeholder="输入机构编号查询" prefix-icon="el-icon-search" v-model="companyCode"> </el-input>
              </div>
              <div class="account">
                <span class="title">网点编码：</span>
                <el-input placeholder="输入网点编码查询" prefix-icon="el-icon-search" v-model="orgCode"> </el-input>
              </div>
              <div class="account">
                <span class="title">网点名称：</span>
                <el-input placeholder="输入网点名称查询" prefix-icon="el-icon-search" v-model="orgName"> </el-input>
              </div>
            </div>
            <el-button @click="serarch">搜 索</el-button>
          </div>
          <div class="tab">
            <el-table
              :data="tableData"
              v-loading="loading"
              element-loading-text="正在加载数据"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0,.1)"
              border
              style="width: 100%"
            >
              <el-table-column prop="orgCode" label="机构编码"> </el-table-column>
              <el-table-column prop="orgName" width="400" label="机构名称"> </el-table-column>
              <el-table-column prop="operationStatus" label="运营状态">
                <template slot-scope="scope">{{ scope.row.operationStatus | operationStatus }}</template>
              </el-table-column>
              <el-table-column prop="type" label="类型">
                <template slot-scope="scope">{{ scope.row.type | type }}</template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              background
              :page-sizes="[10, 20, 40, 60, 100]"
              :page-size="pageSize"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
export default {
  name: 'Organizationalstructure',
  data() {
    return {
      loading: false,
      loadingTwo: false,
      input2: '',
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      userCode: '',
      treeList: [],
      companyCode: '',
      orgCode: '',
      orgName: '',
      defaultProps: {
        children: 'children',
        label: 'orgName',
      },
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    this.getOrganization();
    this.getList();
  },
  // 过滤器
  filters: {
    operationStatus(value) {
      var operationStatus;
      if (value == '1') {
        operationStatus = '运营';
        return operationStatus;
      } else if (value == '2') {
        operationStatus = '停运';
        return operationStatus;
      }
    },
    type(value) {
      var type;
      if (value == 'P') {
        type = '公司';
        return type;
      } else if (value == 'B') {
        type = '机构';
        return type;
      } else if (value == 'S') {
        type = '网点';
        return type;
      }
    },
  },
  methods: {
    serarch() {
      this.pageNum = 1;
      this.getList();
    },
    handleNodeClick(data) {
      this.companyCode = data.orgCode;
      this.pageNum = 1;
      this.getList();
    },
    // 获取组织架构
    getOrganization() {
      this.loadingTwo = true;
      var url = `/system/dept/tree?id=1`;
      this.$axios({
        url: url,
        method: 'get',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.treeList = [];
        if (res.data.code == 200) {
          res.data.data.forEach((ele) => {
            this.treeList.push(ele);
          });
        }
        this.loadingTwo = false;
      });
    },
    recursive(node) {
      // 如果存在孩子节点，则递归访问孩子节点
      if (node) {
        if (node.children && node.children.length > 0) {
          node.children.orgName = `${node.children.orgCode}-${node.children.orgName}`;
          for (let child of node.children) {
            child.orgName = `${child.orgCode}-${child.orgName}`;
            this.recursive(child);
          }
        }
      }
    },
    // 获取列表
    getList() {
      this.tableData = [];
      this.loading = true;
      var url = `/system/dept/list `;
      this.$axios({
        url: url,
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          function: 'list',
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          companyCode: this.companyCode,
          orgCode: this.orgCode,
          orgName: this.orgName,
        }),
      }).then((res) => {
        this.total = res.data.total;
        if (res.data.code == 0) {
          res.data.rows.forEach((element) => {
            this.tableData.push(element);
          });
          this.loading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  width: 1500px;
  margin: 0 auto;
  min-height: 550px;
}
.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.demo-input-suffix {
  display: flex;
  align-content: center;
  align-items: center;
}
.account:last-child {
  margin-left: 20px;
}
.account:nth-child(2) {
  margin-left: 20px;
}
.account {
  display: flex;
  align-content: center;
  align-items: center;
  .title {
    width: 120px;
  }
}
.el-button:hover {
  border-radius: none;
  border: 1px solid #ec9368;
}
.el-button {
  width: 100px;
  padding: 0;
  color: #fff;
  background: #ec9368;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: none;
  margin-left: 20px;
}
.content {
  display: flex;
  justify-content: space-between;
  .organization {
    width: 400px;
    height: 800px;
    overflow: scroll;
  }
  .tabBox {
    width: 950px;
  }
}
.tab {
  margin: 20px 0 0 0;
}
/deep/ .el-tree-node__content {
  min-height: 35px;
}
/deep/ .el-tree-node {
  white-space: normal;
}
.page {
  text-align: center;
  margin: 40px 0;
}
/deep/ .el-table th,
.el-table tr {
  background: #ec9368;
  color: #fff;
}
.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
  color: #c0c4cc !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ec9368 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
  color: #ec9368 !important;
}
/deep/ .el-pager li.active + li {
  border: none !important;
}
</style>
